import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Auth from '../Components/auth';
import { ToastContainer, toast } from 'react-toastify';
import { QRCodeSVG } from 'qrcode.react';

function Qrcode() {
  const walletAddress = localStorage.getItem('web3_addess');

  // Function to copy wallet address to clipboard
  const copyToClipboard = () => {
    if (walletAddress) {
      navigator.clipboard.writeText(walletAddress);
      toast.success('Wallet address copied to clipboard!');
    } else {
      toast.error('No wallet address available to copy.');
    }
  };

  // Function to share wallet address via Web Share API
  const shareAddress = async () => {
    if (navigator.share && walletAddress) {
      try {
        await navigator.share({
          title: 'Wallet Address',
          text: `Here is my wallet address: ${walletAddress}`,
        });
        toast.success('Wallet address shared successfully!');
      } catch (error) {
        toast.error('Sharing failed.');
      }
    } else {
      toast.error('Share feature is not supported on this device.');
    }
  };

  return (
    <>
      <div>
        <Auth />
        <ToastContainer />
        <div className="header">
          <div className="tf-container">
            <div className="tf-statusbar d-flex justify-content-center align-items-center">
              <Link to="/web3wallet" className="back-btn">
                <i className="icon-left" />
              </Link>
              <h3>QR Code</h3>
            </div>
          </div>
        </div>
        <div className="wrap-qr">
          <div className="tf-container">
            <h2 className="fw_6 text-center">Scan Your QR Code</h2>
            <div className="logo-qr text-center mt-5">
              {walletAddress ? (
                <QRCodeSVG value={walletAddress} size={256} />
              ) : (
                <p>No Wallet Address Available</p>
              )}
            </div>
            <div className="mt-5 text-center">
              <h6>BEP20 Address</h6>
              <h3 className="mt-5">
                {walletAddress
                  ? walletAddress.slice(0, 15) + '......' + walletAddress.slice(-15)
                  : 'No Address'}
              </h3>
            </div>
          </div>
        </div>
        <div className="bottom-navigation-bar bottom-btn-fixed">
          <div className="tf-container d-flex gap-3">
            {/* Copy Address Button */}
            <button onClick={copyToClipboard} className="tf-btn accent medium">
              Copy Address
            </button>

            {/* Share Address Button */}
            <button onClick={shareAddress} className="tf-btn outline medium">
              Share Address
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Qrcode;
