import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import Auth from '../Components/auth';
import { useState } from 'react';
import { ToastContainer,toast } from 'react-toastify';
function Home() {


    const myData = localStorage.getItem('id');

    const [isMining,setMining]=useState(false);
    const [isMinButton,setMineButton]=useState(false);
    const [isMineStatus,setMineStatus]=useState(true);
    const [stakeid, setStakeId] = useState(null);

    const gameUrl = `https://games.rainocoin.com/home?userID=${myData}`;

    const startMining = ()=>{

        setMining(true);

        fetch('https://api.rainocoin.com/dailyMining.php?userid='+localStorage.getItem('id')+'&stakeId='+stakeid)
        .then(res => res.json())
        .then(
            (mineStatus) =>{

                console.log('user Data',mineStatus);

                if(mineStatus.error === '200'){

                    setMining(false);
                    setMineStatus(true);

                    toast.success(mineStatus.message,{ position: toast.POSITION.TOP_RIGHT, });
                    
                 getStakeData();
                 
                }
                if(mineStatus.error === '400'){

                    setMining(false);
                    setMineStatus(false);

                    toast.error(mineStatus.message,{ position: toast.POSITION.TOP_RIGHT, });
                }
            }
        )
    }


    const getStakeData = () =>{

        fetch('https://api.rainocoin.com/miningStatus.php?userid='+localStorage.getItem('id'))
        .then(res => res.json())
        .then(
            (stakeStatus) =>{

               // console.log(stakeStatus);

                if(stakeStatus.error === '200'){

                    setStakeId(stakeStatus.id);

                  //  console.log(stakeid);
                  if(stakeStatus.c_date === stakeStatus.MineDateTime){


                    setMineStatus(true);
                  }else{

                    setMineStatus(false);
                    setMining(false);

                  }

                };

                if(stakeStatus.error === '400'){

                    setMineStatus(false);
                    setMining(false);
                }
            }
        )
    }

    useEffect(() =>{

        getStakeData();
    
    },[])
   
    return (
        <div>
        <Auth/>
        <ToastContainer />
            <div className="app-header">
                <div className="tf-container">
                    <div className="tf-topbar d-flex justify-content-between align-items-center">
                        <Link className="user-info d-flex justify-content-between align-items-center" to="/profile">
                            <img src="assets/images/user/profile1.jpg" alt="image" />
                            <div className="content">
                                <h4 className="white_color">{localStorage.getItem('name')}</h4>
                                <p className="white_color fw_4">Let’s save your money!</p>
                            </div>
                        </Link>
                        <Link to="#" id="btn-popup-up" className="icon-notification1"><span>2</span></Link>
                    </div>
                </div>
            </div>
            <div className="card-secton">
                <div className="tf-container">
                    <div className="tf-balance-box">
                        <div className="balance">
                            <div className="row">
                                <div className="col-6 br-right">
                                    <div className="inner-left">
                                        <p>Your Balance:</p>
                                        <h3>${localStorage.getItem('directAvailable')}</h3>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="inner-right">
                                        <p>Reward</p>
                                        <h3>
                                        <ul className="icon-gift-box">
                                                <li className="path1" />
                                                <li className="path2" />
                                                <li className="path3" />
                                                <li className="path4" />
                                                <li className="path5" />
                                                <li className="path6" />
                                                <li className="path7" />
                                                <li className="path8" />
                                                <li className="path9" />
                                                <li className="path10" />
                                            </ul>
                                            {localStorage.getItem('avlPoint')}
                                            <span>Raino</span>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="wallet-footer">
                            <ul className="d-flex justify-content-between align-items-center">
                                <li className="wallet-card-item">
                                    <Link className="fw_6 text-center" id="btn-popup-down" to={gameUrl}>
                                        <img class='icon icon-topup' src='assets/images/gameicon.png'/>
                                        Games
                                    </Link>
                                </li>
                                <li className="wallet-card-item"><Link className="fw_6" to="/topup">
                                    <ul className="icon icon-topup">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                    </ul>
                                    Deposit
                                </Link></li>
                                <li className="wallet-card-item"><Link className="fw_6 btn-card-popup" to="/wallet">
                                    <ul className="icon icon-group-credit-card">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                    </ul>
                                    Wallet
                                </Link></li>
                                <li className="wallet-card-item"><Link className="fw_6" to="/withdraw">
                                    <ul className="icon icon-my-qr">
                                        <li className="path1" />
                                        <li className="path2" />
                                        <li className="path3" />
                                        <li className="path4" />
                                        <li className="path5" /> 
                                        <li className="path6" />
                                        <li className="path7" />
                                    </ul>
                                    Withdraw
                                </Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <div className="tf-container">
                    
                    <ul className="box-service mt-3">
                        <li>
                            <Link to="/referral">
                                <div className="icon-box bg_color_1">
                                <svg fill="#002db3" width="25px" height="25px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" stroke="#002db3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M18,14c-.053,0-.1.014-.155.016L14.817,8.838a4,4,0,1,0-5.632,0L6.166,14.017C6.109,14.014,6.057,14,6,14a4,4,0,1,0,3.859,5h4.282A3.994,3.994,0,1,0,18,14Zm-3.859,3H9.859a3.994,3.994,0,0,0-1.731-2.376l2.793-4.79a3.589,3.589,0,0,0,2.161,0l2.8,4.786A3.989,3.989,0,0,0,14.141,17Z"></path></g></svg>
                                </div>
                                Referrals
                            </Link>
                        </li>
                        <li>
                            <Link to="/myteam">
                                <div className="icon-box bg_color_2">
                                <i class="icon icon-user3" fill="#002db3" width="25px" height="25px" viewBox="0 0 24 24"></i>
                                </div>
                                Team
                            </Link>
                        </li>
                        <li>
                            <Link to="/stake">
                                <div className="icon-box bg_color_3">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.87511 1.25C5.49974 1.24954 5.1266 1.30757 4.76911 1.422C4.00043 1.65173 3.32708 2.12475 2.85029 2.76998C2.37349 3.4152 2.11901 4.19775 2.12511 5V10C2.12564 10.7292 2.41554 11.4284 2.93115 11.944C3.44676 12.4596 4.14592 12.7495 4.87511 12.75H8.87511C9.07402 12.75 9.26479 12.671 9.40544 12.5303C9.54609 12.3897 9.62511 12.1989 9.62511 12V5C9.62379 4.00585 9.22827 3.05279 8.5253 2.34981C7.82232 1.64684 6.86926 1.25133 5.87511 1.25Z" fill="#DA9B00" />
                                        <path d="M23.6248 6.00002V20C23.625 20.3612 23.5541 20.7189 23.416 21.0527C23.2779 21.3865 23.0753 21.6897 22.8199 21.9452C22.5645 22.2006 22.2612 22.4031 21.9275 22.5412C21.5937 22.6793 21.236 22.7503 20.8748 22.75H10.8748C10.5136 22.7503 10.1558 22.6793 9.82208 22.5412C9.48831 22.4031 9.18505 22.2006 8.92964 21.9452C8.67422 21.6897 8.47167 21.3865 8.33356 21.0527C8.19545 20.7189 8.1245 20.3612 8.12477 20V4.25002C8.12328 3.54389 7.87275 2.86092 7.41729 2.32131C6.96182 1.78171 6.33062 1.42006 5.63477 1.30002C5.70929 1.26345 5.79184 1.24626 5.87477 1.25002H18.8748C20.1342 1.25108 21.3418 1.75186 22.2324 2.64243C23.1229 3.533 23.6237 4.74057 23.6248 6.00002Z" fill="#FECC0E" />
                                        <path d="M15.875 8.75H12.875C12.6761 8.75 12.4853 8.67098 12.3447 8.53033C12.204 8.38968 12.125 8.19891 12.125 8C12.125 7.80109 12.204 7.61032 12.3447 7.46967C12.4853 7.32902 12.6761 7.25 12.875 7.25H15.875C16.0739 7.25 16.2647 7.32902 16.4053 7.46967C16.546 7.61032 16.625 7.80109 16.625 8C16.625 8.19891 16.546 8.38968 16.4053 8.53033C16.2647 8.67098 16.0739 8.75 15.875 8.75Z" fill="white" />
                                        <path d="M18.875 11.75H12.875C12.6761 11.75 12.4853 11.671 12.3447 11.5303C12.204 11.3897 12.125 11.1989 12.125 11C12.125 10.8011 12.204 10.6103 12.3447 10.4697C12.4853 10.329 12.6761 10.25 12.875 10.25H18.875C19.0739 10.25 19.2647 10.329 19.4053 10.4697C19.546 10.6103 19.625 10.8011 19.625 11C19.625 11.1989 19.546 11.3897 19.4053 11.5303C19.2647 11.671 19.0739 11.75 18.875 11.75Z" fill="white" />
                                        <path d="M18.875 14.75H12.875C12.6761 14.75 12.4853 14.671 12.3447 14.5303C12.204 14.3897 12.125 14.1989 12.125 14C12.125 13.8011 12.204 13.6103 12.3447 13.4697C12.4853 13.329 12.6761 13.25 12.875 13.25H18.875C19.0739 13.25 19.2647 13.329 19.4053 13.4697C19.546 13.6103 19.625 13.8011 19.625 14C19.625 14.1989 19.546 14.3897 19.4053 14.5303C19.2647 14.671 19.0739 14.75 18.875 14.75Z" fill="white" />
                                        <path d="M18.875 17.75H12.875C12.6761 17.75 12.4853 17.671 12.3447 17.5303C12.204 17.3897 12.125 17.1989 12.125 17C12.125 16.8011 12.204 16.6103 12.3447 16.4697C12.4853 16.329 12.6761 16.25 12.875 16.25H18.875C19.0739 16.25 19.2647 16.329 19.4053 16.4697C19.546 16.6103 19.625 16.8011 19.625 17C19.625 17.1989 19.546 17.3897 19.4053 17.5303C19.2647 17.671 19.0739 17.75 18.875 17.75Z" fill="white" />
                                    </svg>
                                </div>
                                Stake
                            </Link>
                        </li>
                        <li>
                            <Link to="/trade">
                                <div className="icon-box bg_color_4">
                                    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4355_16088)">
                                            <path d="M20.3247 10.3362C20.1985 10.1213 19.9678 9.98934 19.7186 9.98934H14.5737L15.2515 0.754543C15.2756 0.425527 15.0678 0.12398 14.7518 0.0294335C14.4357 -0.0653009 13.7855 -0.288481 13.625 -0.000246391L6.91695 12.9651C6.79568 13.1828 6.79878 13.4485 6.92506 13.6634C7.05134 13.8783 7.28201 14.0102 7.53124 14.0102H12.6762L11.625 23.9998H12.625C12.9436 24.0952 13.1548 23.9244 13.3139 23.6386L20.3329 11.0346C20.4541 10.8168 20.451 10.5511 20.3247 10.3362Z" fill="#C8A52A" />
                                            <path d="M19.3247 10.3364C19.1985 10.1215 18.9678 9.98956 18.7186 9.98956H13.5737L14.2515 0.754767C14.2756 0.425751 14.0678 0.124204 13.7518 0.0296573C13.4357 -0.0650771 13.0965 0.0729229 12.936 0.361157L5.91695 12.9653C5.79568 13.1831 5.79878 13.4487 5.92506 13.6636C6.05134 13.8785 6.28201 14.0105 6.53124 14.0105H11.6762L10.9983 23.2453C10.9742 23.5743 11.182 23.8758 11.4981 23.9704C11.8167 24.0658 12.1548 23.9246 12.3139 23.6389L19.3329 11.0348C19.4541 10.817 19.451 10.5513 19.3247 10.3364Z" fill="#F2C31C" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4355_16088">
                                                <rect width={24} height={24} fill="white" transform="translate(0.625)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                Trade
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='mt-3'>
              <div className='d-flex justify-content-center align-items-center flex-column'>
              <div class="mt-3">
              {isMining ?
                 <div className='mt-3'>
                    <h4 className='text-center'>Please Wait</h4>
                    <img src="assets/images/mining.gif" style={{width:"150px",height:"150px"}}alt="image" />
                 </div>

                 :

                 <div>
                 {isMineStatus ? 
                    <div className='mt-3'>
                    <h4 className='text-center'>Today Mining is Done</h4>
                    <img src="assets/images/done.png" style={{width:"150px",height:"150px"}}alt="image" />
                 </div>
                 :
                 <div className='mt-3'>
                    <h4 className='text-center'>Press Here For Mining</h4>
                    
                    <a href="javascript:void(0)" onClick={startMining}><img src="assets/images/MiningButton.png" style={{width:"150px",height:"150px"}}alt="image" /></a>
                 </div>
                 }
                 </div>
              }
              </div>
              </div>
            </div>
            {/* footer */}
            <div className="bottom-navigation-bar">
                <div className="tf-container">
                    <ul className="tf-navigation-bar">
                        <li className="active"><Link className="fw_6 d-flex justify-content-center align-items-center flex-column" to="/"><i className="icon-home2" /> Home</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/history"><i className="icon-history" /> History</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column"  to="/web3wallet"><i className="icon-scan-qr-code" /> </Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/rewards"><svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12.25" cy={12} r="9.5" stroke="#717171" />
                            <path d="M17.033 11.5318C17.2298 11.3316 17.2993 11.0377 17.2144 10.7646C17.1293 10.4914 16.9076 10.2964 16.6353 10.255L14.214 9.88781C14.1109 9.87213 14.0218 9.80462 13.9758 9.70702L12.8933 7.41717C12.7717 7.15989 12.525 7 12.2501 7C11.9754 7 11.7287 7.15989 11.6071 7.41717L10.5244 9.70723C10.4784 9.80483 10.3891 9.87234 10.286 9.88802L7.86469 10.2552C7.59257 10.2964 7.3707 10.4916 7.2856 10.7648C7.2007 11.038 7.27018 11.3318 7.46702 11.532L9.2189 13.3144C9.29359 13.3905 9.32783 13.5 9.31021 13.607L8.89692 16.1239C8.86027 16.3454 8.91594 16.5609 9.0533 16.7308C9.26676 16.9956 9.6394 17.0763 9.93735 16.9128L12.1027 15.7244C12.1932 15.6749 12.3072 15.6753 12.3975 15.7244L14.563 16.9128C14.6684 16.9707 14.7807 17 14.8966 17C15.1083 17 15.3089 16.9018 15.4469 16.7308C15.5845 16.5609 15.6399 16.345 15.6033 16.1239L15.1898 13.607C15.1722 13.4998 15.2064 13.3905 15.2811 13.3144L17.033 11.5318Z" stroke="#717171" strokeWidth="1.25" />
                        </svg>
                            Rewards</Link> </li>
                        <li><Link className="fw_4 d-flex justify-content-center align-items-center flex-column" to="/profile"><i className="icon-user-outline" /> Profile</Link> </li>
                    </ul>
                    {/* <span class="line"></span> */}
                </div>
            </div>
            {/* <Footer/> */}
            
        </div>
    );
}
export default Home;